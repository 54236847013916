
import { defineComponent, onMounted, ref } from "vue";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import ToastService from "@/core/services/ToastService";
import { displayErrors } from "@/core/helpers/errors";
import { useRoute } from "vue-router";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { setCurrentPageActions } from "@/core/helpers/toolbar";

export default defineComponent({
  name: "users-form",
  emit: ["update-success"],
  components: {},
  props: {},
  setup(props, { emit }) {
    const store = useStore();
    const router = useRouter();
    const formRef = ref<null | HTMLFormElement>(null);
    const loading = ref<boolean>(false);
    const formData = ref({
      password: "", // Solo el campo de usuario
      repeatpassword: "",
      recovertoken: "",
    });

    const formToken = ref({
      recovertoken: "", // Solo el campo de usuario
    });

    const rules = ref({
      password: [
        {
          required: true,
          message: "Este campo es obligatorio",
          trigger: "blur",
        },
        {
          max: 50,
          message: "Este campo admite máximo 50 caracteres",
          trigger: "change",
        },
      ],
      repeatpassword: [
        {
          required: true,
          message: "Este campo es obligatorio",
          trigger: "blur",
        },
        {
          max: 50,
          message: "Este campo admite máximo 50 caracteres",
          trigger: "change",
        },
        {
          validator: (rule, value, callback) => {
            if (value !== formData.value.password) {
              callback(new Error("Las contraseñas no coinciden"));
            } else {
              callback();
            }
          },
          trigger: "blur",
        },
      ],
    });

    const route = useRoute(); // Accede a la ruta actual
    const token = Array.isArray(route.params.token)
      ? route.params.token[0]
      : route.params.token;

    onMounted(() => {
      setCurrentPageBreadcrumbs("Usuarios", ["Usuarios", "Nuevo"]);
      setCurrentPageActions(false, false, []);
      formToken.value.recovertoken = token;

      storeLoad();
    });

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formData.value.recovertoken = token;

      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;
          store
            .dispatch(Actions.UPDATE_PASSWORD, formData.value)
            .then((response) => {
              loading.value = false;
              if (store.state.AuthModule.success) {
                router.push({
                  name: "sign-in",
                });
                ToastService.show(
                  "La contraseña se actualizo de manera exitosa.",
                  "success"
                );
              }
            })
            .catch((response) => {
              displayErrors(store, "AuthModule");
              loading.value = false;
            });
        } else {
          ToastService.show(
            "Lo sentimos, parece que se han detectado algunos errores. Vuelve a intentarlo.",
            "error"
          );
          return false;
        }
      });
    };

    const storeLoad = () => {
      store
        .dispatch(Actions.VALIDATOR_TOKEN, formToken.value)
        .then((response) => {
          loading.value = false;
          if (store.state.AuthModule.success) {
            ToastService.show("Ingresa la nueva contraseña", "success");
          }
        })
        .catch((response) => {
          displayErrors(store, "AuthModule");
          loading.value = false;
          router.push({
            name: "sign-in",
          });
        });
    };

    return {
      formData,
      formToken,
      rules,
      submit,
      formRef,
      loading,
      token,
    };
  },
});
